import React, { useEffect, useRef, useState } from 'react';
import { formatPriceComic } from '../../utils/MoneyFormats';
import ComicPreview from './ComicPreview';
import './OrderListComic.scss';
import { getImage } from '../../utils/ImageHandler';
import { PeriodType } from '../../common';

export interface PositionOfDiv {
  top: number,
  left: number,
}

type Props = ComicProps & {
  orderItems: IOrderItem[],
  addComic: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, comic: IComic) => void,
  handleSearch: (searchTerm: string) => void,
}

// https://i.annihil.us/u/prod/marvel/i/mg/f/70/590cddbb47d6d/clean.jpg

const OrderListComic: React.FC<Props> = ({ comic, orderItems, addComic, handleSearch }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [position, setPosition] = useState<PositionOfDiv>({top:0, left:0});
  const buttonRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  let theOrderItem = orderItems.find(comicOrder => comicOrder.comicId === comic.comicId );

  const toggleComicPreview = () => {
    
    setShowPreview(!showPreview);

    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: buttonRect.top,
        left: buttonRect.left
      });
    }
  };
  
  useEffect(() => {
    if (!showPreview) {
      return;
    }
    const div = popupRef.current;
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth-280; // to not cover the Your Order element
    const divHeight = div ? div.offsetHeight + 10 : 0;
    const divWidth = div ? div.offsetWidth + 10 : 0;

    // console.log( divHeight + ' ' + divWidth);

    // move up
    if (position.top + divHeight > viewportHeight) {
      setPosition((prevPosition) => ({
        ...prevPosition,
        top: (viewportHeight - divHeight)
      }));
    }

    // move down
    if (position.top < 0) {
      setPosition((prevPosition) => ({
        ...prevPosition,
        top: 10
      }));
    }

    // move left
    if (position.left + divWidth > viewportWidth) {
      setPosition((prevPosition) => ({
        ...prevPosition,
        left: (viewportWidth - divWidth)
      }));
    }
  }, [showPreview, position]);

  const handleSearchTrigger = (searchTerm:string) => {
    setShowPreview(false);
    handleSearch(searchTerm);
  }

  // console.log(`image ID ` + comic.imageId + `  ` + comic.hasImage);
  const comicPrice = formatPriceComic(comic, comic.batch.discountPercent);
  const ableToPurchase = (theOrderItem === undefined) || (comic.number !== undefined && comic.number - theOrderItem.quantity > 0);

  const imgCss = comic.periodType === PeriodType.NEW ? 'comic-item-cover' : comic.periodType === PeriodType.BACK_ISSUE ? 'comic-item-cover-bi' : 'comic-item-cover-game';
  
  return (
    <div className='comic-item'>
      <div className='clickable-wrapper'>
        <div className='clickable' ref={buttonRef} onClick={toggleComicPreview}></div>
      </div>
      <div>
        {showPreview && (<ComicPreview popupRef={popupRef} comicInput={comic} orderItem={theOrderItem} position={position} handleSearch={handleSearchTrigger} toggleComicPreview={toggleComicPreview} addComic={addComic} />)}
        {comic.batch.discountPercent ?
          (<div className='comic-tag-wrapper'>
            <div className='comic-tag-inner'>
              <div className='comic-tag'>{comic.batch.discountPercent}<span className='small'>% OFF</span></div>
            </div>
          </div>) : <></>}
        {comic.hasImage === undefined || comic.hasImage ?
          <div className="comic-item-holder "><img className={imgCss} src={getImage(comic)} alt='comic preview'/></div>
          : <div className='comic-item-cover-no-image' />}
        <div className='comic-name-wrapper'>
          <div className='comic-item-name'>{comic.mainDescription}</div>
          <div className='comic-item-name'>{comic.variantDescription}</div>
        </div>
      </div>
      {/* <div className='comic-item-company-name'>{formatPublisher(comic)}</div> */}
      <div className={ableToPurchase ? 'comic-item-purchase-button' : 'comic-item-non-purchase-button'} onClick={(e) => ableToPurchase && addComic(e, comic)}>
        <div className='comic-item-price'>
          <span className='big-price'>${comicPrice.substring(0, comicPrice.indexOf('.'))}</span>
          <span className='little-price'>{comicPrice.substring(comicPrice.indexOf('.'))}</span>
        </div>
        {(ableToPurchase)?
          (
          <div className='purchase-icon'>
            <img src="/img/purchase.svg" alt='purcase comic'/>
          </div>
          )
          : (<div className="comic-sold-out">You have last the one</div>)}
      </div>
    </div>
  )
}



// 

export default OrderListComic;
