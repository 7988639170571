import React, { useState } from 'react'
import { formatMoney, formatPriceComic } from '../../utils/MoneyFormats'

import './ViewEditStockItemSwitcher.scss';
import EbayControls from '../order/EbayControls'
import { getImage } from '../../utils/ImageHandler'
import { Link } from 'react-router-dom';
import { getItemTypeName, ItemType, getPeriodTypeName, PeriodType, getSupplierTypeName, SupplierType } from '../../common';

type Props = ComicProps & {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  deleteStock: (formComic: IComic) => void,
  addStock?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic) => void,
  handleEditMode: () => void,
}

const ViewNewStockItem: React.FC<Props> = ({ comic, formComic, setFormComic, deleteStock, addStock, handleEditMode }) => {

  const [result, setResult] = useState('');

  return (
    <div>
      <div>
        {formComic !== undefined ?
          (<div className="comic-item-preview-holder admin-preview right"><img className='comic-item-preview-cover' alt='cover' src={getImage(formComic)} /></div>)
          : <div className='comic-item-preview-no-image right' />}

        <h1 className='inline no-top-margin'>{comic.mainDescription}</h1>{formComic.variantDescription !== '' ? (<>&nbsp;&nbsp;•&nbsp;&nbsp;</>) : ''}
        <h2 className='inline no-top-margin'>{comic.variantDescription}</h2><br />
        <span className='inline no-top-margin'>{comic.comicId} -- {comic.releaseMonth}/{comic.releaseYear} -- {comic.publisher}</span><br />
        <span className='inline no-top-margin'>{comic.batch?.name} -- {comic.batchDate} -- {comic.type}</span>
        <div>{getItemTypeName(comic.itemType as ItemType)}&nbsp;•&nbsp;{getPeriodTypeName(comic.periodType as PeriodType)}&nbsp;•&nbsp;{getSupplierTypeName(comic.supplierType as SupplierType)}&nbsp;•&nbsp;({comic.type})</div>
        <h1>x{comic.number} (of {comic.initialQuantity})&nbsp;&nbsp;&nbsp;&nbsp;{<span>${formatPriceComic(comic, comic.batch.discountPercent)}</span>}</h1>
        <h4>
          Sold: {comic.salesQuantity} at ${formatMoney(comic.salesValue / comic.salesQuantity)} = ${formatMoney(comic.salesValue)}
        </h4>
        <div>{comic.images.length > 0 ? comic.images.length + 'x images (' + JSON.stringify(comic.images) + ')' : ''}</div>
        <div>
          <button className="link-button" onClick={() => deleteStock(comic)}>delete</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <button className="link-button" onClick={() => handleEditMode()}>edit</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <Link to='/admin-add-back-issue' state={{ copyComic:formComic }} className="link-button">copy</Link>&nbsp;&nbsp;•&nbsp;&nbsp;
          <EbayControls comic={formComic} setComic={setFormComic} setResult={setResult} />
          {result !== '' ? (<div>{result}</div>) : ''}
          { addStock && <button type="button" onClick={(e) => addStock(e, comic)} className="link-button right">add</button> }
        </div>
      </div>
    </div>);
}

export default ViewNewStockItem;