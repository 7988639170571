// import swal from 'sweetalert2';

import { OldComicType, PeriodType } from "../common";
import { AMAZON_S3 } from "./UrlConsts";

export const getImageUrl = (comic: IComic) => {
  const image =
    comic.periodType === PeriodType.NEW ? AMAZON_S3 + '/diamond/' + comic.comicId + '.webp' :
      comic.periodType === PeriodType.BACK_ISSUE ? AMAZON_S3 + '/backissues/' + comic.comicId + '.webp' :
        AMAZON_S3 + '/games/' + comic.comicId + '.webp';

  return image;
}

export const getImageIdUrl = (comic: IComic, imageId: string) => {
  const image =
    comic.periodType === PeriodType.NEW ? AMAZON_S3 + '/diamond/' + imageId + '.webp' :
      comic.periodType === PeriodType.BACK_ISSUE ? AMAZON_S3 + '/backissues/' + imageId + '.webp' :
        AMAZON_S3 + '/games/' + imageId + '.webp';

  return image;
}

export const getImage = (comic: IComic) => {
  return getImageUrl(comic);
}


export const getOrderItemUrl = (orderItem: IOrderItem) => {

  // console.log( 'orderItem.type ' + orderItem.type );
  if (orderItem.periodType) {
    const image =
      orderItem.periodType === PeriodType.NEW ?  AMAZON_S3 + '/diamond/' + orderItem.comicId + '.webp' :
      orderItem.periodType === PeriodType.BACK_ISSUE ? AMAZON_S3 + '/backissues/' + orderItem.comicId + '.webp' :
                                          AMAZON_S3 + '/games/' + orderItem.comicId + '.webp';
    return image;
  }
  else if (orderItem.type) {
    const image =
      orderItem.type === OldComicType.NEW ?  AMAZON_S3 + '/diamond/' + orderItem.comicId + '.webp' :
      orderItem.type === OldComicType.BACK ? AMAZON_S3 + '/backissues/' + orderItem.comicId + '.webp' :
                                          AMAZON_S3 + '/games/' + orderItem.comicId + '.webp';

    return image;
  }
  else {
    // this is legacy code for when we didn't have orderItem.type
    const image =
      orderItem.comicId.indexOf('PXL') === -1 ?
        AMAZON_S3 + '/diamond/' + orderItem.comicId + '.webp' :
        AMAZON_S3 + '/backissues/' + orderItem.comicId + '.webp';

    return image;
  }
}
